import { useCallback } from 'react';
import { events } from '@/shared/services/events';
import { getErrorMessage } from '@/shared/functions/errors';
import { useAccountStore } from '@/shared/state';
import { sendErrorToNewRelic } from '../functions/newRelic';
import { getAccounts } from '../services/api/instant-messenger/service/getAccounts';

export const useFetchAccount = () => {
  const { setAccount } = useAccountStore();

  return useCallback(async () => {
    try {
      const accounts = await getAccounts();
      setAccount(accounts.data[0]);
    } catch (err) {
      const msg = await getErrorMessage(err);
      events.error({ description: msg });
      sendErrorToNewRelic(err);
    }
  }, [setAccount]);
};
