import { FC } from 'react';
import { styled, Flex, FlexProps } from '@grupoboticario/flora-react';

export const ChatItemWrapper = styled(Flex, {
  position: 'relative',
  lineHeight: '$6',
  cursor: 'pointer',
  padding: '$3 $4 $3 $4',
  transition: 'all 200ms ease-in-out',

  '&:not(:first-of-type)': {
    borderTop: '1px solid #d1d7db',
  },

  '&:first-child:last-child': {
    borderBottom: '1px solid #d1d7db',
  },

  '&:hover': {
    backgroundColor: '#f5f6f6',
    borderTopColor: 'transparent',
    opacity: '1 !important',
  },

  '&:hover > *': {
    transition: 'all 200ms ease-in-out',
    opacity: '1 !important',
  },

  '&& .btn-add-tag': {
    opacity: '0.2',
    transition: 'all 200ms ease-in-out',
  },

  '&:hover .btn-add-tag': {
    opacity: '1 !important',
  },

  variants: {
    selected: {
      true: {
        '&&': {
          backgroundColor: 'rgb(233 235 249)',
        },
        borderTopColor: 'transparent',
        boxShadow: 'inset -4px 0 0 var(--flora--colors-actionableDefault)',
        opacity: '1',
      },
      false: {
        '& *': {
          opacity: '0.6',
        },
        '.contact-basic-info, .contact-basic-info > span + span, .chat-contact-name, .tags-list, .tags-list *, .btn-add-tag, .btn-add-tag *, .menu-contact-tags, .menu-contact-tags *':
          { opacity: '1' },
      },
    },
    status: {
      read: {},
      unread: {
        '&&>div': {
          opacity: '1',
        },
        '&::after': {
          content: '',
          width: '12px',
          height: '12px',
          position: 'absolute',
          bottom: '1rem',
          right: '44px',
          backgroundColor: 'var(--flora--colors-whatsapp)',
          display: 'block',
          borderRadius: '50%',
        },
      },
    },
  },
}) as FC<FlexProps & { selected: boolean; status: 'read' | 'unread' }>;
