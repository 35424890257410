import { useEffect } from 'react';

import { ContactSearch } from '@/features/ContactSearch';
import { ChatList } from '@/features/ChatList';
import { MessagesList } from '@/features/MessagesList';

import { globalStyles } from '@/shared/styles/globalStyles';
import { useNotifications } from '@/shared/hooks';
import {
  AppContainer,
  ChatWrapper,
  ChatsListWrapper,
  MessagesListWrapper,
  ContactSearchWrapper,
  Notify,
} from '@/shared/components';
import { useFetchContact } from './shared/hooks/useFetchContact';
import { useUrlParams } from './shared/hooks/useUrlParams';
import { useFetchAccount } from './shared/hooks/useFetchAccount';
import { useAccountStore } from './shared/state';

export default function Root() {
  globalStyles();
  useNotifications();
  const fetchContact = useFetchContact();
  const fetchAccount = useFetchAccount();
  const urlParams = useUrlParams();
  const contactVdId = urlParams.get('contact');
  const { account } = useAccountStore();

  useEffect(() => {
    if (contactVdId && account?.id) {
      history.replaceState('', '', '/chat');
      fetchContact(contactVdId);
    }
  }, [contactVdId, account?.id]);

  useEffect(() => {
    if (!account) {
      fetchAccount();
    }
  }, [account?.id]);

  if (!account) {
    return <div>Carregando...</div>;
  }

  return (
    <AppContainer as="main">
      <Notify />
      <ChatWrapper>
        <ChatsListWrapper data-testid="left-column">
          <ContactSearchWrapper>
            <ContactSearch onSubmit={fetchContact} />
          </ContactSearchWrapper>
          <ChatList />
        </ChatsListWrapper>
        <MessagesListWrapper data-testid="right-column">
          <MessagesList />
        </MessagesListWrapper>
      </ChatWrapper>
      <div id="image-gallery"></div>
      <div id="image-upload-preview"></div>
    </AppContainer>
  );
}
