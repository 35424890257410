import { useEffect, useState } from 'react';
import { getResellerCredits } from '@/shared/services/api/ca/services';
import { getErrorMessage } from '@/shared/functions';
import { events } from '@/shared/services/events';
import { Credits } from '@/shared/types/interfaces';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

export const useResellerCredits = (vdId: string) => {
  const [credits, setCredits] = useState<Credits | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    async function fetchResellerCredits() {
      try {
        const res = await getResellerCredits(vdId, signal);
        setCredits(res);
      } catch (err) {
        if (err.name !== 'AbortError' && err.response?.status !== 404) {
          const msg = await getErrorMessage(err);
          events.error({ description: msg });
          sendErrorToNewRelic(err);
        }
      }
    }

    if (vdId) {
      fetchResellerCredits();
    }

    return () => {
      controller.abort();
    };
  }, [vdId]);

  return credits;
};
