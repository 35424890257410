import { useCallback, useEffect, useState } from 'react';
import { Tag } from '@/shared/types/interfaces';
import { getTags } from '@/shared/services/api/instant-messenger/service';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { events } from '@/shared/services/events';

export function useFetchTags() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [tags, setTags] = useState<Array<Tag>>([]);

  const fetchTags = useCallback(async () => {
    try {
      setLoading(true);
      const tags = await getTags();
      setTags(tags.data);
    } catch (error) {
      setError('Erro ao carregar as tags: ' + (error.message || 'Erro desconhecido.'));
      sendErrorToNewRelic(error);
      events.error({ description: 'Erro ao carregar as tags' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  return { loading, error, tags };
}
