import { useCallback } from 'react';
import { getResellerData } from '@/shared/services/api/instant-messenger/service/getResellerData';
import { events } from '@/shared/services/events';
import { getErrorMessage } from '@/shared/functions/errors';
import { useContactStore } from '@/shared/state';
import { sendErrorToNewRelic } from '../functions/newRelic';

export const useFetchContact = () => {
  const { setContact } = useContactStore();

  return useCallback(
    async (vdId: string) => {
      if (!vdId) {
        return;
      }
      try {
        const contact = await getResellerData(vdId);
        setContact(contact);
      } catch (err) {
        const msg = await getErrorMessage(err);
        events.error({ description: msg });
        sendErrorToNewRelic(err);
      }
    },
    [setContact],
  );
};
